<template>
	<v-row no-gutters class="fill-height" align="center" justify="center">
		<v-container class="text-center">
			<template v-for="(item, index) in options">
				<v-card
					v-if="(isProductionEnv && !item.fade) || !isProductionEnv"
					:key="index"
					width="320"
					height="240"
					class="custom-card-wr elevation-5 overflow-hidden ms-5 mt-3 d-inline-block"
					@click="!item.fade ? fireEvent(item) : ''"
				>
					<v-row no-gutters align="center" justify="center" class="fill-height">
						<v-col align="center">
							<v-chip
								small
								color="warning"
								v-if="item.fade"
								class="coming-soon-btn-wr font-weight-bold subtitle-2"
							>
								{{ $t("app.coming_soon") }}
							</v-chip>
							<v-card-text>
								<v-img
									width="100%"
									height="80"
									aspect-ratio="1"
									contain
									:src="require(`@/assets/images/${item.icon}.png`)"
									:class="{ 'vertical-transform-wr': $vuetify.rtl }"
								></v-img>
							</v-card-text>
							<v-card-text class="title-wr font-weight-bold pb-0 mt-2">
								{{ $t(item.title) }}
							</v-card-text>
						</v-col>
					</v-row>
					<v-footer
						v-if="item.subtitle"
						absolute
						bottom
						color="white"
						class="font-weight-thin subtitle-1 pt-0 mt-0"
					>
						<v-row no-gutters justify="center">{{ $t(item.subtitle) }}</v-row>
					</v-footer>
				</v-card>
			</template>
		</v-container>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "AppBoxTheme",

	props: {
		options: {
			required: true,
			type: Array,
		},
		md_column_size: {
			type: Number,
			default: 4,
		},
		lg_column_size: {
			type: Number,
			default: 4,
		},
	},

	computed: {
		...mapState({
			mini: (state) => state.globals.mini,
		}),

		isProductionEnv() {
			return this.$environments.environment == "production";
		},
	},

	methods: {
		async fireEvent(payload) {
			try {
				this.$loader.start();

				// Emit event to parent component
				await this.$emit("proceed", payload.route);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.custom-card-wr {
	transition: 0.4s !important;
}

.custom-card-wr:hover {
	border: 3px solid var(--v-greenAccent2-base);
	transform: scale(1.05);
}

>>> .v-image__image--cover {
	transform: rotateY(180) !important;
}

.coming-soon-btn-wr {
	position: absolute;
	top: 2%;
	left: 1%;
	z-index: 1;
}

.title-wr {
	font-size: 19px;
}
</style>
