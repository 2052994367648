<template>
	<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "MentorToolsPage",

	data() {
		return {
			options: [
				{
					title: "app.meet_with_students",
					icon: "online-meeting",
					route: {
						name: "MentorMeet",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.meetings_summary",
					icon: "analysis",
					route: {
						name: "MentorMeetingsList",
					},
				},
				{
					title: "app.tools.standard_mind_map.title",
					icon: "mindmap",
					route: {
						name: "MentorStandardMindmapList",
					},
				},
				{
					title: "app.tools.wizard_mind_map.title",
					icon: "step",
					route: {
						name: "MentorToolsWizardmindMap",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.tools.research_editor.title",
					icon: "file",
					route: {
						name: "MentorToolsEditor",
					},
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
